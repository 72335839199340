import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import { Menu, Button } from 'semantic-ui-react';
import content from './content';

const Nav = ({ goTo, history, location, logout }) => {
  const activePath = location.pathname;

  const [activeItem, setActiveItem] = useState(activePath);
  const navigateTo = (path) => {
    setActiveItem(path);
    history.push(path);
    // console.log(history);
    // this.props.history.push(path);
  }

  return (
    <Menu pointing>
      <Menu.Item
        name='/individual'
        active={activeItem === '/individual'}
        onClick={() => { navigateTo('/individual') }}
      >
        {content('individual')}
      </Menu.Item>
      <Menu.Item
        name='/company'
        active={activeItem === '/company'}
        onClick={() => { navigateTo('/company') }}
      >
        {content('company')}
      </Menu.Item>
      <Menu.Item>
        <Button onClick={logout} basic size='mini'>Logout</Button>
      </Menu.Item>
    </Menu>
  )
}
export default withRouter(Nav);
      //
      // <Menu.Item name='reviews' active={activeItem === 'reviews'} onClick={this.handleItemClick}>
      //   Reviews
      // </Menu.Item>
      //
      // <Menu.Item
      //   name='upcomingEvents'
      //   active={activeItem === 'upcomingEvents'}
      //   onClick={this.handleItemClick}
      // >
      //   Upcoming Events
      // </Menu.Item>
