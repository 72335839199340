import React, { useState } from 'react';
import { Button, Pagination, Label } from 'semantic-ui-react'
import styled from 'styled-components';
import { OrderedList } from './utils';

const Center = styled.div`
  text-align: center;
  margin-top: 10px;
`;

const NameTag = styled.div`
  display: inline-block;
  margin: 0 2px;
  text-align: left;
`;
const Annotation = styled.div`
  font-size: 10px;
`;

function OtherCombinations(props) {
  const [page, setPage] = useState(1);
  let { combinations, searchIndividual } = props;
  if (!combinations) return null;
  if (!searchIndividual) {
    searchIndividual = () => {}
  }
  const pageSize = 10;
  const totalPages = Math.ceil(combinations.length / pageSize);
  const displayableItems = combinations.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
  const handlePaginationChange = (e, { activePage }) => setPage(activePage)
  //
  // const soundRomajiFirst = ((props.firstnameRefs || {}).otherRomaji) || {};
  // const soundRomajiLast = ((props.lastnameRefs || {}).otherRomaji) || {};
  // const nameRomajiFirst = ((props.firstnameRefs || {}).romaji) || {};
  // const nameRomajiLast = ((props.lastnameRefs || {}).romaji) || {};
  const activeName = props.activeItem && `${props.activeItem.first} ${props.activeItem.last}`;

  return (
    <React.Fragment>
      <OrderedList ordered celled>
        {displayableItems.map((c, cindex) => (
          <li
            key={cindex}
            as='a'
          >
            <Button
              onClick={searchIndividual}
              as='div'
              labelPosition='left'
              negative={c.match}
              basic={!c.match}
              size={activeName === `${c.Forename} ${c.Surname}` ? 'large' : 'mini'}
              fluid
              value={JSON.stringify({first:c.Forename, last: c.Surname})}
            >
              <Label>{cindex + 1 + pageSize * (page - 1)}</Label>
              <Button
                compact
                fluid
                size={activeName === `${c.Forename} ${c.Surname}` ? 'large' : 'mini'}
                negative={c.match}
                basic={!c.match}
              >
                <NameTag>
                  {c.Forename.toUpperCase()}
                </NameTag>
                <NameTag>
                  {c.Surname.toUpperCase()}
                </NameTag>
              </Button>
            </Button>
          </li>
        ))}
      </OrderedList>
      <Center>
      {displayableItems.length > 0 && (
        <Pagination
          totalPages={totalPages}
          activePage={page}
          onPageChange={handlePaginationChange}
          boundaryRange={0}
          siblingRange={2}
          ellipsisItem={null}
          size='mini'
        />
      )}
      </Center>
    </React.Fragment>
  )
}

export default OtherCombinations;
