import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Button, Segment, Form, Input, Card, Radio, Tab, Divider, Dropdown, Loader, Flag, Dimmer, Label, Icon, Header, Grid, List } from 'semantic-ui-react'
import OtherCombinations from './OtherCombinations';
import content from './content';
import SearchDetails from './SearchDetails';
import personKanji from './mockapi/personKanji';

const Padded = styled.div`
  padding: 10px 20px;

  @media (min-width: 700px) {
    width: 50vw;
  }
`;
const Option = styled.div`
  display: inline-block;
  margin: 0 5px;
`;

const Advanced = styled.div`
  margin: 10px 0 10px 0;
  > div:first-of-type {
    text-align: center;
  }
`;
const Float = styled.div`
  position: absolute;
  right: 5px;
`;
const StaticSearch = styled.div`
  margin-right: 10px;
  display: inline-block;
  > label {
    margin-right: 5px;
    font-size: 12px;
    margin-left: 10px;
  }
`;

const NameResult = styled.div`
  display: inline-block;
  max-width: 100px;
  vertical-align: top;
`;

const Row = styled.div`
  margin: 5px 0;
`;

class SearchPeople extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      advancedSearchMode: false,
      genderSelect: null
    };

    this.names = [{
      last: '豊山',
      first: '明博',
      fullName: '豊山明博'
    }, {
      last: '福田',
      first: '晴瞭',
      fullName: '福田晴瞭'
    }, {
      last: '森尾',
      first: '卯太男',
      fullName: '森尾卯太男'
    }, {
      last: '高木',
      first: '康男',
      fullName: '高木康男'
    }];

    this.selectName = this.selectName.bind(this);
    this.onYearChange = (e, { value }) => {
      this.setState(() => ({
        yearSelect: value,
      }))
    }

    this.search = this.search.bind(this);
    this.onGenderChange = this.onGenderChange.bind(this);
    this.clearAll = () => {
      this.setState(() => ({
        name: null,
        data: null,
        loading: false,
        advancedSearchMode: false,
        genderSelect: null,
        searchCriteria: null,
        yearSelect: null,
      }))
    }

    this.clearFilter = () => {
      this.setState(() => ({
        searchCriteria: null,
      }))
    }

    this.expandAdvance = () => {
      this.setState(prevState => ({
        advancedSearchMode: !prevState.advancedSearchMode
      }))
    }
    // this.search = (e, { value }) => {
    //   let name;
    //   if (value) {
    //     name = JSON.parse(value);
    //     console.log(name);
    //   }
    // }
  }

  selectName(e, { value }) {
    if (!value) return;
    const name = JSON.parse(value);
    // const { first, last } = name;
    this.setState(() => ({
      name,
    }));

  }

  async search() {
    // if (!value) return;
    // const name = JSON.parse(value);
    const { first, last } = this.state.name;
    const { yearSelect, genderSelect } = this.state;
    this.setState(() => ({
      loading: true,
    }));

    const result = personKanji({first, last});
    setTimeout(() => {
      if (result) {
        var { data } = result;
        this.setState(() => ({
          data: data,
          searchCriteria: {
            yearSelect, genderSelect
          },
          loading: false,
        }))
      }
    }, 2000)


    // if (result) {
    //   var { data } = result;
    //   // console.log(data.data);
    //   this.setState(() => ({
    //     data: data,
    //     // data: Object.assign({}, data.data, {
    //     //   blacklistMatches: Object.keys(data.data.blacklistMatches).reduce((a, c) => {
    //     //     let result = data.data.blacklistMatches[c];
    //     //     const newResult = Object.assign({}, result, {
    //     //       match: result.match.filter(p => {
    //     //         if (p.entity && p.entity.gender && genderSelect) {
    //     //           return p.entity.gender === genderSelect
    //     //         }
    //     //         return true;
    //     //       })
    //     //     })
    //     //     // if (result.match) {
    //     //     //   result.match =
    //     //     // }
    //     //     return Object.assign({}, a, {
    //     //       [c]: newResult
    //     //     });
    //     //   }, {})
    //     // }),
    //     searchCriteria: {
    //       yearSelect, genderSelect
    //     },
    //     loading: false,
    //   }))
    // }

  }

  onGenderChange(e, { value }) {
    this.setState(() => ({
      genderSelect: value,
    }))
  }

  render() {
    // <Label key={match} basic color='red'>{match.toUpperCase()}</Label>
    const { data: rawData, loading, name, advancedSearchMode, genderSelect, searchCriteria } = this.state;

    const data = searchCriteria ? Object.assign({}, rawData, {
     blacklistMatches: Object.keys(rawData.blacklistMatches).reduce((a, c) => {
       let result = rawData.blacklistMatches[c];

       const newResult = Object.assign({}, result, {
         match: result.match.filter(p => {

           if (p.entity) {
             let filter = true;
             if (searchCriteria.genderSelect) {
               if (p.entity.gender && p.entity.gender !== searchCriteria.genderSelect) {
                 filter = false;
               }
             }
             if (searchCriteria.yearSelect) {
               if (p.entity.nameStartDateStr && parseInt(p.entity.nameStartDateStr, 10) > parseInt(searchCriteria.yearSelect, 10)) {
                 filter = false;
               }
             }
             return filter
           }
           return true;
         })
       })
       return Object.assign({}, a, {
         [c]: newResult
       });
     }, {})
   }) : rawData;
    const riskyEntityCard = (risk, key) => {
      const { match, matchName } = risk;
      if (!match || !match[0]) return null;
      return (
        <Card fluid key={key}>
          <Card.Content>
            <Card.Header
            >{key.toUpperCase()}</Card.Header>
            {matchName && (
              <Card.Meta>
                <Label size='medium'>Fuzzy match from <Label color='grey' horizontal>{matchName.toUpperCase()}</Label></Label>
              </Card.Meta>
            )}
            <Card.Meta>
              <a
                target='_blank'
                rel='noreferrer noopener'
                href={`https://platform.datarama.com/person/${match[0].entityId}`}
              >
              Go to company page on Datarama
              </a>

            </Card.Meta>
            {match[0].entity && (
              <Card.Description>
                Year of Birth {match[0].entity.nameStartDateStr} <Label basic>{match[0].entity.gender}</Label> <Flag name={match[0].entity.nationality} />
              </Card.Description>
            )}
            <Divider />
            <Card.Description>
              <List divided>
                {match.map((risk, riskIndex) => (
                  <List.Item key={riskIndex}>
                    <List.Content>
                      <List.Header>
                        {risk.riskType.countryId ? (
                          <Flag name={risk.riskType.countryId} />
                        ) : <List.Icon name='globe' />}
                        {risk.riskType.riskTypeDescStr} ({risk.riskType.country})
                      </List.Header>
                      <List.Description>Detail: {risk.riskDetail} <Button size='mini' basic as='a' compact href={risk.sourceUrlStr} target="_blank">Source</Button></List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </Card.Description>
          </Card.Content>
        </Card>
      )
    };

    const panes = [{
      menuItem: content('result'),
      render: () => (
        <React.Fragment>
        {Object.keys(data.blacklistMatches).filter(b => data.blacklistMatches[b].match.length > 0).length > 0 ? (
          <React.Fragment>
            <Label color='red'>
              <Icon name='exclamation triangle' size='large' />
              {Object.keys(data.blacklistMatches).filter(b => data.blacklistMatches[b].match.length > 0).length} converted name{Object.keys(data.blacklistMatches).length > 1 ? 's' : ''} found with matches as <u>Risky Entity</u>
            </Label>
            {Object.keys(data.blacklistMatches).map(match => riskyEntityCard(data.blacklistMatches[match], match))}
          </React.Fragment>
        ) : (
          <div>
            {content('nomatch')}
            {rawData && Object.keys(rawData.blacklistMatches).length > 0 && (
              <div>
                <Divider />
                <Label basic>
                  {Object.keys(rawData.blacklistMatches).length} converted name{Object.keys(rawData.blacklistMatches).length > 1 ? 's' : ''} found with matches as <u>Risky Entity</u> with name alone
                </Label>
                <Button onClick={this.clearFilter} fluid size='mini'>Show all results</Button>
              </div>
            )}
          </div>
        )}
        </React.Fragment>
      )
    }, {
      menuItem: content('details'),
      render: () => (
        <SearchDetails data={data} />
      )
    }]

    return (
      <Padded>
        <Segment>
        {data ? (
          <React.Fragment>
            <Float>
              <Button floated='right' compact icon basic size='small' onClick={this.clearAll}>
                  <Icon name='delete' />
              </Button>
            </Float>
            <Label ribbon size='large'>検索結果</Label>
            <StaticSearch>
              <label>苗字</label>
              <Label size='large' basic horizontal>{name.last}</Label>
            </StaticSearch>
            <StaticSearch>
              <label>名</label>
              <Label size='large' basic horizontal>{name.first}</Label>
            </StaticSearch>
            {searchCriteria && Object.keys(searchCriteria).filter(p => searchCriteria[p]).length > 0 && (
              <React.Fragment>
                <Divider horizontal>{content('advancedFilter')}</Divider>
                {searchCriteria.genderSelect && (
                  <StaticSearch>
                    <label>{content('gender')}</label>
                    <Label size='large' color={searchCriteria.genderSelect === 'female' && 'pink' || searchCriteria.genderSelect === 'male' && 'blue'} basic horizontal>{searchCriteria.genderSelect}</Label>
                  </StaticSearch>
                )}
                {searchCriteria.yearSelect && (
                  <StaticSearch>
                    <label>Born Before</label>
                    <Label size='large' basic horizontal>{searchCriteria.yearSelect}</Label>
                  </StaticSearch>
                )}
                <Button onClick={this.clearFilter} basic size='mini'><Icon name='delete' /> Clear Filter</Button>
              </React.Fragment>
            )}
            {data.lastname && data.lastname.isLikelyKoreanSurname && (
              <div>
                <br/>
                <Label basic color='orange'><Icon name='check' />{content('koreanMatch')}</Label>
              </div>
            )}

          </React.Fragment>
        ) : (
          <React.Fragment>
            <Header>{content('individual')}</Header>
            <Form onSubmit={this.search}>
              <Dropdown
                placeholder='Select sample Individual'
                clearable
                selection
                search
                onChange={this.selectName}
                options={this.names.map(name => ({
                  key: name.fullName,
                  text: name.fullName,
                  value: JSON.stringify(name),
                }))}
                fluid
              />
              <Segment >
                <div onClick={this.expandAdvance}>
                  <b><Icon name={`chevron ${advancedSearchMode ? 'up' : 'down'}`} /> {content('advancedFilter')}</b>
                </div>
                {advancedSearchMode && (
                  <React.Fragment>
                    <Row>
                      {content('bornBefore')}
                      <Option>
                        <Input size='small' placeholder='Year' type='number' min={1910} max={2019} onChange={this.onYearChange}/>
                      </Option>
                    </Row>
                    <Row>
                      {content('gender')}{'  '}
                      <Option>
                      <Radio
                        label={content('female')}
                        name='gender'
                        value='female'
                        checked={genderSelect === 'female'}
                        onChange={this.onGenderChange}
                      />
                      </Option>
                      <Option>
                      <Radio
                        label={content('male')}
                        name='gender'
                        value='male'
                        checked={genderSelect === 'male'}
                        onChange={this.onGenderChange}
                      />
                      </Option>

                    </Row>
                  </React.Fragment>
                )}
              </Segment>

              <Button type="submit" loading={loading} disabled={loading || !name} color="green" basic fluid>{content('search')}</Button>
            </Form>
          </React.Fragment>
        )}
        </Segment>
        {data && (
          <Segment>
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
          </Segment>
        )}
      </Padded>
    )
  }
}

export default SearchPeople;
