import suisan from './0.json';
import nissan from './1.json';
import daiwa from './2.json';
import ship from './3.json';
import morinaga from './4.json';

export default {
  0: suisan,
  1: nissan,
  2: daiwa,
  3: ship,
  4: morinaga,
}
