import React from 'react';
import styled from 'styled-components';
import { Tab, Dropdown, Label, List } from 'semantic-ui-react'
import OtherCombinations from './OtherCombinations';
import content from './content';

const Padded = styled.div`
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  padding: 10px;
`;
class SearchDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'allResults',
    };
    this.selectTab = (e, { value }) => {
      this.setState(() => ({
        selectedTab: value,
      }))
    }
  }

  render() {
    const { data } = this.props;
    const { selectedTab } = this.state;
    const panes = [{
      menuItem: content('allResults'),
      render: () => (
        <Padded>
          Total {data.allCombinations.length} combinations
          <OtherCombinations
            combinations={data.allCombinations}
            searchIndividual={this.searchIndividual}
          />
        </Padded>
      )
    }, {
      menuItem: content('surname'),
      render: () => (
        <Padded>
          <Label ribbon>{data.lastname.kana.length} {content('name')}</Label>
          <List ordered celled>
            {data.lastname.kana.map((k, idx) => (
              <List.Item key={k}>{k}</List.Item>
            ))}
          </List>
          {data.otherKana && data.otherKana.lastname && (
            <Label ribbon>{data.otherKana.lastname.length} {content('yomi')}</Label>
          )}
          <List ordered celled>
          {data.otherKana && data.otherKana.lastname && data.otherKana.lastname.map((kana, idx) => (
            <List.Item key={`other-${kana}`} value={`${idx + 1}`}>{kana}</List.Item>
          ))}
          </List>
        </Padded>
      )
    }, {
      menuItem: content('korean'),
      render: () => (
        <div>
        {data.lastname && data.lastname.isLikelyKoreanSurname ? (
          <React.Fragment>
            <div >
            {data.lastname.isLikelyKoreanSurname.korean && data.lastname.isLikelyKoreanSurname.korean.map(ko => (
              <Label key={ko.korean} basic >
                {ko.korean}
              </Label>
            ))}<Label>{data.firstname.kanji}</Label>
            </div>
            <div>
              <OtherCombinations
                combinations={data.koreanNames}
                searchIndividual={this.searchIndividual}
              />
            </div>
          </React.Fragment>
        ): (
          <Padded>
          {content('nokorean')}
          </Padded>
        )}
        </div>
      )
    }]
    const options = [{
      key: 'allResults',
      text: content('allResults'),
      value: 'allResults',
    }, {
      key: 'surname',
      text: content('surname'),
      value: 'surname',
    }, {
      key: 'firstname',
      text: content('firstname'),
      value: 'firstname',
    }, {
      key: 'korean',
      text: content('korean'),
      value: 'korean',
    }]
    const showContent = () => {
      switch (selectedTab) {
        case 'allResults':
          return (
            <Padded>
              Total {data.allCombinations.length} combinations
              <OtherCombinations
                combinations={data.allCombinations}
                searchIndividual={this.searchIndividual}
              />
            </Padded>
          )
        case 'surname':
          return (
            <Padded>
              <Label ribbon>{data.lastname.kana.length} {content('name')}</Label>
              <List ordered celled>
                {data.lastname.kana.map((k, idx) => (
                  <List.Item key={k}>{k}</List.Item>
                ))}
              </List>
              {data.otherKana && data.otherKana.lastname && (
                <Label ribbon>{data.otherKana.lastname.length} {content('yomi')}</Label>
              )}
              <List ordered celled>
              {data.otherKana && data.otherKana.lastname && data.otherKana.lastname.map((kana, idx) => (
                <List.Item key={`other-${kana}`} value={`${idx + 1}`}>{kana}</List.Item>
              ))}
              </List>
            </Padded>
          )
        case 'firstname':
          return (
            <Padded>
              <Label ribbon>{data.firstname.kana.length} {content('name')}</Label>
              <List ordered celled>
              {data.firstname.kana.map((k, idx) => (
                <List.Item key={k}>{k}</List.Item>
              ))}
              </List>
              {data.otherKana && data.otherKana.firstname && (
                <Label ribbon>{data.otherKana.firstname.length} {content('yomi')}</Label>
              )}
              <List ordered celled>
              {data.otherKana && data.otherKana.firstname && data.otherKana.firstname.map(kana => (
                <List.Item key={`other-${kana}`}>{kana}</List.Item>
              ))}
              </List>
            </Padded>
          )
        case 'korean':
          return (
            <div>
            {data.lastname && data.lastname.isLikelyKoreanSurname ? (
              <React.Fragment>
                <div >
                <br/>
                {content('surname')}
                {data.lastname.isLikelyKoreanSurname.korean && data.lastname.isLikelyKoreanSurname.korean.map(ko => (
                  <Label key={ko.korean} basic >
                    {ko.korean}
                  </Label>
                ))}
                <br/>
                {content('firstname')}
                <Label>{data.firstname.kanji}</Label>
                </div>
                <div>
                  <OtherCombinations
                    combinations={data.koreanNames}
                    searchIndividual={this.searchIndividual}
                  />
                </div>
              </React.Fragment>
            ): (
              <Padded>
              {content('nokorean')}
              </Padded>
            )}
            </div>
          )
        default:
          return null;
      }
    }
    return (
      <div>
        <Dropdown
          placeholder='Select'
          fluid
          selection
          options={options}
          value={selectedTab}
          onChange={this.selectTab}
        />
        {showContent(selectedTab)}
      </div>
    )
  }

}

export default SearchDetails
