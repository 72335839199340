import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Header, Segment, Card, Icon, Flag, Button, Label, Divider, Dropdown, Tab, List, Form } from 'semantic-ui-react';
import SearchDetailsCompany from './SearchDetailCompany';
import content from './content';
import companyKanji from './mockapi/companyKanji';

const Padded = styled.div`
  padding: 10px 20px;
  
  @media (min-width: 700px) {
    width: 50vw;
  }
`;

const Float = styled.div`
  position: absolute;
  right: 5px;
`;

const StaticSearch = styled.div`
  margin-right: 10px;
  display: inline-block;
  > label {
    margin-right: 5px;
    font-size: 12px;
    margin-left: 10px;
  }
`;
const Tag = styled.div`
  display: inline;
`;

const NameResult = styled.div`
  vertical-align: top;
  display: grid;
  grid-template-columns: 30% 70%;
  margin-bottom: 5px;
`;
const Names = styled.div`
  display: inline-block;
`;

const Row = styled.div`
  margin: 5px 0;
`;

class SearchCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.names = ['日本水産株式会社', '日産自動車株式会社', '大和証券投資信託委託株式会社', '日本郵船株式会社', '森永乳業株式会社'];

    this.search = this.search.bind(this);
    this.selectName = this.selectName.bind(this);
  }

  async search() {
    // e.preventDefault();
    // if (!value) return;

    this.setState(() => ({
      loading: true,
    }));
    const { name } = this.state;
    if (!name) return;
    const searchIndex = this.names.indexOf(name);
    var result = companyKanji(searchIndex);
    setTimeout(() => {
      if (result) {
        var { data } = result;
        this.setState(() => ({
          data,
          loading: false,
        }))
      }
    }, 1500)

    // var result = await axios.get(`http://localhost:3090/dictionary/${searchIndex}`)
  }

  selectName(e, { value }) {
    // const { first, last } = name;
    this.setState(() => {
      if (!value) {
        return {
          data: null,
          loading: null,
          name: null,
        }
      }
      return {
        name: value
      }
    });

  }

  render() {
    const { data, loading, name } = this.state;
    // console.log(data);
    const panes = [{
      menuItem: content('result'),
      render: () => (
        <div>
          {Object.keys(data.matches).length > 0 ? (
            <React.Fragment>
              <Label color='red'>
                <Icon name='exclamation triangle' size='large' />
                {Object.keys(data.matches).length} converted name{Object.keys(data.matches).length > 1 ? 's' : ''} found with matches as <u>Risky Entity</u>
              </Label>
              <Divider />
            </React.Fragment>
          ) : (
            <div>No match as risky entity</div>
          )}

        {data.matches.map(match => (
          <div key={match.name}>
            <Card fluid>
              <Card.Content>
                <Card.Header
                  as='a'
                  target='_blank'
                  href={`https://platform.datarama.com/organisation/${match.risk[0].entityId}`}
                >{match.name.toUpperCase()}</Card.Header>
                <Card.Meta>
                  <a
                    target='_blank'
                    rel='noreferrer noopener'
                    href={`https://platform.datarama.com/organisation/${match.risk[0].entityId}`}
                  >
                  Go to company page on Datarama
                  </a>

                </Card.Meta>
                <Card.Description>
                  {match.risk.map((risk, riskIndex) => (
                    <List.Item key={riskIndex}>
                      <List.Content>
                        <List.Header>
                          {risk.countryId ? (
                            <Flag name={risk.countryId} />
                          ) : <List.Icon name='globe' />}
                          {risk.riskTypeDescStr} ({risk.country})
                        </List.Header>
                        <List.Description>Detail: {risk.riskDetail}</List.Description>
                        <List.Description>
                          <Button size='mini' basic as='a' compact href={risk.sourceUrlStr} target="_blank">Source</Button>
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  ))}
                </Card.Description>
              </Card.Content>
            </Card>
          </div>
        ))}

        </div>
      )
    }, {
      menuItem: content('details'),
      render: () => (
        <SearchDetailsCompany data={data} />
      )
    }];
    // <Segment>
    // {data.word && data.word.map(w => (
    //   <NameResult key={w.japanese}>
    //   <div>
    //   <Label>{w.japanese}</Label>
    //   </div>
    //   <Names>
    //   {w.english && w.english.map(br => (
    //     <Label key={br} size='small' basic>{br}</Label>
    //   ))}
    //   </Names>
    //   </NameResult>
    // ))}
    // <Divider />
    // <div>
    // <Header as='h3'>Combinations ({data.combinations.length})</Header>
    // {data.combinations.map(comb => (
    //   <Row key={comb.name}>
    //   <Label horizontal key={comb.name} basic color={comb.isRiskyEntity && 'red'}>{comb.name}</Label>
    //   </Row>
    // ))}
    // </div>
    // </Segment>

    return (
      <Padded>
        <Segment>
          {data ? (
            <React.Fragment>
              <Float>
                <Button floated='right' compact icon basic size='small' onClick={this.selectName}>
                  <Icon name='delete' />
                </Button>
              </Float>
              <Label ribbon size='large'>検索結果</Label>
              <StaticSearch>
                <Label size='large' basic horizontal>{name}</Label>
              </StaticSearch>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Header>会社 Company</Header>
              <Form onSubmit={this.search}>
                <Dropdown
                  placeholder='Select sample Company'
                  clearable
                  fluid
                  selection
                  search
                  onChange={this.selectName}
                  options={this.names.map(name => ({
                    key: name,
                    text: name,
                    value: name,
                  }))}
                />
                <br/>
                <Button type="submit" loading={loading} disabled={loading || !name} color="green" basic fluid>検索 Search</Button>
              </Form>
            </React.Fragment>
          )}

        </Segment>
        {data && (
          <Segment>
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
          </Segment>
        )}
      </Padded>
    )
  }
}

export default SearchCompany;
