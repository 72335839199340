import React from 'react';
import { Button, Segment, Form, Input, Message } from 'semantic-ui-react'
import styled from 'styled-components';
import { fbauth } from './lib/firebase';
import content from './content';

const Padded = styled.div`
  padding: 0 20px;

  @media (min-width: 700px) {
    margin: 0 auto;
    width: 50vw;
    max-width: 400px;
  }
`;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
    this.login = this.login.bind(this);
    this.clearAll = () => {
      this.setState(() => ({
        error: null,
        email: null,
        password: null,
      }))
    }
    this.fieldChange = (e, { name, value }) => {
      this.setState(() => ({
        [name]: value
      }))
    }
    this.setError = () => {
      this.setState(() => ({
        error: true,
        loading: false,
      }))
    }
    this.setLoadingStatus = (status) => {
      this.setState(() => ({
        loading: status,
      }))
    }
  }

  login() {
    const { setLoggedInUser } = this.props;
    const { setError, setLoadingStatus } = this;
    const { email, password } = this.state;
    if (!email || !password) return;
    setLoadingStatus(true);
    fbauth.signInWithEmailAndPassword(email, password)
      .then(async result => {
        var user = result.user;
        const token = await user.getIdToken();
        setLoggedInUser(user, token);
        setLoadingStatus(false);

      }).catch(function(error) {
        setError('login')
      });

  }

  render() {
    const { error, loading } = this.state;

    return (
      <Padded>
        {error && (
          <Message
            negative
            header="Cannot log in"
            content="Please double check email and password and try again."
            onDismiss={this.clearAll}
          />
        )}
        <Segment>
          <Form onSubmit={this.login}>
            <Form.Field>
              <label>{content('email')}</label>
              <Input placeholder='Email' required onChange={this.fieldChange} name='email' />
            </Form.Field>
            <Form.Field>
              <label>{content('password')}</label>
              <Input type='password' placeholder='Password' required onChange={this.fieldChange} name='password'/>
            </Form.Field>
            <Button type="submit" fluid color='green' loading={loading} basic>{content('login')}</Button>
          </Form>
        </Segment>
      </Padded>
    )
  }
}

export default Login;
