import toyoyamatadahiko from '../results/toyoyamatadahiko.json';
import morio from '../results/morio.json';
import takagi from '../results/takagi.json';
// import osaka from '../results/oosaka.json';
import fukuda from '../results/fukuda.json';

export default {
  '豊山明博': toyoyamatadahiko,
  '森尾卯太男': morio,
  '高木康男': takagi,
  // '大坂なおみ': osaka,
  '福田晴瞭': fukuda
}
