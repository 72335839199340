import React from 'react';
import styled from 'styled-components';
import firebase from 'firebase';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react'

import SearchCompany from './SearchCompany';
import SearchPeople from './SearchPeople';
import Nav from './Nav';
import 'semantic-ui-css/semantic.min.css';
import Login from './Login';
import { fbauth } from './lib/firebase';

const palette = {
  darkBlue: '#425677',
  lightBlue: '#516991',
  selectedBlue: '#3821E8',
  hoverBlue: '#503ceb',
  bgLightBlue: '#42536b',
  entityBgGrey: '#f2f2f2',
  bgBlue: '#293445',
  bgBlueLighter: '#3b4557',
  orange_01: '#FF8737',
  yellow_01: '#ffd80d'
};
const Padded  = styled.div`
  padding: 20px;
`;
const Wrapper = styled.div`
  background: ${palette.darkBlue};
  min-height: 100vh;
  width: 100vw;
  user-select: none;
`;
const Header = styled.div`
  > * {
    vertical-align: middle;
    padding: 0 10px;
  }
`;

const Subheader = styled.div`
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
`;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.setUserSession = this.setUserSession.bind(this);
    this.logout = this.logout.bind(this);
    this.setLoggedInUser = (user, token, acl) => {
      const userObj = {
        name: user.displayName,
        email: user.email,
        token,
        acl,
      };
      this.setState({
        loggedIn: userObj
      })
    }

    this.logoutSession = () => {
      this.setState({
        loggedIn: null,
      })
    }
  }

  componentDidMount() {
    const { setLoggedInUser, setUserSession } = this;
    firebase.auth().onAuthStateChanged(async function (user) {
      if (user) {
        setUserSession(user);
        // const token = await user.getIdToken();
        // const accessRights = await fbdb.ref(`user/${user.uid}`).once('value');
        // setLoggedInUser(user, token, accessRights.val());
      }
    });
  }

  async setUserSession(user) {
    const token = await user.getIdToken();

    const userObj = {
      name: user.displayName,
      email: user.email,
      uid: user.uid,
      token,
    };
    this.setState({
      loggedIn: userObj,
    })
  }

  logout() {
    const { logoutSession } = this;
    fbauth.signOut().then(() => {
      logoutSession();
    });
  }


  render() {
    const { loggedIn } = this.state;
    return (
      <Router>
        <Wrapper>
          <Padded>
            <Header>
              <img src="/datarama_logo.png" width="150px" alt='logo'/>
              <Subheader>Checks with Japanese Kanji Names</Subheader>
              {loggedIn && (
                <Nav logout={this.logout} />
              )}
            </Header>
          </Padded>
          {!loggedIn ? (
            <Login setLoggedInUser={this.setLoggedInUser} />
          ) : (
            <React.Fragment>
              <Route path="/company" component={SearchCompany} />
              <Route path="/individual" component={SearchPeople} />
            </React.Fragment>
          )}
        </Wrapper>
      </Router>
    )
  }
}

export default App;
//
