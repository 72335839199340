const content = {
  result: {
    en: 'Result',
    ja: '検索結果'
  },
  search: {
    en: 'Search',
    ja: '検索'
  },
  advancedFilter: {
    en: 'Advanced Filter',
    ja: 'アドバンスド'
  },
  firstname: {
    en: 'Firstname',
    ja: '名'
  },
  surname: {
    en: 'Surname',
    ja: '苗字'
  },
  allResults: {
    en: 'All',
    ja: '全部'
  },
  individual: {
    en: 'Individual',
    ja: '個人'
  },
  company: {
    en: 'Company',
    ja: '会社'
  },
  email: {
    en: 'Email',
    ja: 'Eメール'
  },
  login: {
    en: 'Login',
    ja: 'ログイン'
  },
  logout: {
    en: 'Logout',
    ja: ''
  },

  password: {
    en: 'Password',
    ja: 'パスワード'
  },
  details: {
    en: 'Details',
    ja: '詳細',
  },
  korean: {
    en: 'Korean',
    ja: 'コリア系',
  },
  gender: {
    en: 'Gender',
    ja: '性別'
  },
  female: {
    en: 'F',
    ja: '女'
  },
  male: {
    en: 'M',
    ja: '男'
  },
  bornBefore: {
    en: 'Born Before',
    ja: '○○年以前に生まれた'
  },
  combinations: {
    en: 'Combinations',
    ja: 'ローマ字の組み合わせ'
  },
  koreanMatch: {
    en: 'Possible linked to Korean Surname',
    ja: 'コリア系苗字'
  },
  nomatch: {
    en: 'No match as risky entity based on critera applied',
    ja: '一致するリスキーエンティティが見つかりませんでした'
  },
  nokorean: {
    en: 'Korean name matches not found',
    ja: '一致するコリア系姓名が見つかりませんでした'
  },
  segment: {
    en: 'Segment',
    ja: 'セグメント'
  },
  yomi: {
    en: ' by Kunyomi / Onyomi',
    ja: '訓読み・音読み'
  },
  name: {
    en: ' by Name',
    ja: ''
  },
  romajiCombo: {
    en: 'by Name',
    ja: ''
  },
  yomiCombo: {
    en: 'by Kunyomi / Onyomi',
    ja: '訓読み・音読み'
  },
}

export default (key, lang) => {
  if (!lang) return `${content[key].ja} ${content[key].en}`
  return content[key][lang];
}
