import React from 'react';
import { Dropdown, Divider, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import content from './content';
import OtherCombinationsCompany from './OtherCombinationsCompany';

const NameResult = styled.div`
  vertical-align: top;
  display: grid;
  grid-template-columns: 30% 70%;
  margin-bottom: 5px;
`;

const Padded = styled.div`
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  padding: 10px;
`;

const Names = styled.div`
  display: inline-block;
`;

const Row = styled.div`
  margin: 5px 0;
`;

class SearchDetailsCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'allResults',
    };
    this.selectTab = (e, { value }) => {
      this.setState(() => ({
        selectedTab: value,
      }))
    }

  }

  render() {
    const { data } = this.props;

    const options = [{
      key: 'allResults',
      text: content('allResults'),
      value: 'allResults',
    }, {
      key: 'segment',
      text: content('segment'),
      value: 'segment'
    }];
    const { selectedTab } = this.state;

    const showContent = () => {
      switch (selectedTab) {
        case 'allResults':
          return (
            <Padded>
              Total {data.combinations.length} combinations
              <OtherCombinationsCompany combinations={data.combinations} />
            </Padded>
          )
          case 'segment':
            return (
              <Padded>
                {data.word && data.word.map(w => (
                  <div key={w.japanese}>
                    <NameResult>
                      <div>
                        <Label>{w.japanese}</Label>
                      </div>
                      <Names>
                        {w.english && w.english.map(br => (
                          <Label key={br} size='small' basic>{br}</Label>
                        ))}
                      </Names>
                    </NameResult>
                    <Divider />
                  </div>
                ))}

              </Padded>
            )
        default:
          return null;
      }
    }
    // <Header as='h3'>Combinations ({data.combinations.length})</Header>

    return (
      <div>
        <Dropdown
          placeholder='Select'
          fluid
          selection
          options={options}
          value={selectedTab}
          onChange={this.selectTab}
        />
        {showContent()}
      </div>
    )
  }
}

export default SearchDetailsCompany;
