import * as firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyAJiUxpTkFA_ew-hjV1r77MP1MV4AlfLTU",
  authDomain: "dtrm-japanese-checks.firebaseapp.com",
  databaseURL: "https://dtrm-japanese-checks.firebaseio.com",
  projectId: "dtrm-japanese-checks",
  storageBucket: "dtrm-japanese-checks.appspot.com",
  messagingSenderId: "351313440732",
  appId: "1:351313440732:web:93aaa5a43328fa9f"
};

firebase.initializeApp(firebaseConfig);

export const fbauth = firebase.auth();
