import styled from 'styled-components';

export const Center = styled.div`
  text-align: center;
  margin-top: 10px;
`;
export const OrderedList = styled.ul`
  li {
    padding: 0;
    margin: 0 0 3px 0;
    text-align: left;
  }
  padding: 0;
  list-style: none;
`;
